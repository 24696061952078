<template lang="pug">
  footer.footer.bg-black2.color-white.mt-auto
    div.container
      div.footer__row
        div.footer__content
          div.footer__nav
            a(href="/").footer__logo.logo.transition
              img(
                :src="FooterLogo"
                alt="logo"
              )
            nav.navigation
              ul
                li
                  router-link(to="/services").link Services
                li
                  router-link(to="/about").link About us
                li
                  router-link(to="/cases").link Cases
                li
                  router-link(to="/vacancies").link Vacancies
                li
                  router-link(to="/contacts").link Contact
          social-block.footer__social
          div.footer__contacts
            div.item
              h3.h3.fw-700 Email
              ul.links
                li
                  a(href="mailto:hr@disoft.dev") hr@disoft.dev
                li
                  a(href="mailto:mail@disoft.dev") mail@disoft.dev
            div.item
              h3.h3.fw-700 Phone number
              ul.links
                li
                  a(href="tel:+16102458044") +1 (610) 245 80 44
            div.item
              h3.h3.fw-700 Main office
              ul.links
                li
                  a(
                    href="https://goo.gl/maps/LdMaZiisQGwFqkEW8"
                    target="_blank"
                  ) 802 Patterson ave. Staten Island, New York 10306. USA

        .footer__map
          a(
            href="https://goo.gl/maps/LdMaZiisQGwFqkEW8"
            target="_blank"
          ).img__wrapp.hover__scale
            img(
              :src="FooterMap"
              alt="map icon"
            )
</template>

<script>
// images
import FooterLogo from '@/assets/icons/logo.svg'
import FooterMap from '@/assets/img/footer-map.svg'
export default {
  name: 'FooterPiece',
  components: {
    SocialBlock: () => import('@/components/SocialBlock')
  },
  data () {
    return {
      FooterLogo,
      FooterMap
    }
  }
}
</script>
